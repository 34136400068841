import './App.css';
import LoginComponent from './components/Auth/LoginComponent';
import { Navbar, Nav, Container, Col, Row, Offcanvas, Card } from 'react-bootstrap';
import { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {  faHome, faNewspaper, faUsers, faFolder } from '@fortawesome/free-solid-svg-icons';

function App(props) {
  const [showSidebar, setShowSidebar] = useState(false);

  const handleSidebarToggle = () => setShowSidebar(!showSidebar);

  return (
    <Container>
      <div className="App">
        {/* Navbar */}
        <Navbar bg="warning" expand="lg" variant="light" className="mb-4">
          <Container>
            <Navbar.Brand href="/">
               SINTEREC CLARO
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
              <Nav className="me-auto">
                <Nav.Link href="/"><FontAwesomeIcon icon={faHome} /> Inicio</Nav.Link>
                <Nav.Link href="/noticias"><FontAwesomeIcon icon={faNewspaper} /> Noticias</Nav.Link>
                <Nav.Link href="/directiva-sindicato"><FontAwesomeIcon icon={faUsers} /> Directiva Sindicato</Nav.Link>
                <Nav.Link href="/documentos"><FontAwesomeIcon icon={faFolder} /> Documentos</Nav.Link>
              </Nav>
              <Nav>
                <Nav.Link onClick={handleSidebarToggle}>Mi Sindicato</Nav.Link>
              </Nav>
            </Navbar.Collapse>
          </Container>
        </Navbar>

        {/* Sidebar */}
        <Offcanvas show={showSidebar} onHide={handleSidebarToggle} placement="end">
          <Offcanvas.Header closeButton>
            <Offcanvas.Title>Admin Panel</Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body>
            <Nav className="flex-column">
              <Nav.Link href="/admin/dashboard">Dashboard</Nav.Link>
              <Nav.Link href="/admin/usuarios">Usuarios</Nav.Link>
              <Nav.Link href="/admin/noticias">Noticias</Nav.Link>
              <Nav.Link href="/admin/documentos">Documentos</Nav.Link>
            </Nav>
          </Offcanvas.Body>
        </Offcanvas>

        {/* Main Content */}
        <main className="App-main" data-bs-theme="dark">
          <Container>
            <Row className="justify-content-center align-items-center min-vh-100">
              <Col md={6} lg={4}>
                <Card>
                  <LoginComponent />
                </Card>
              </Col>
            </Row>
          </Container>
        </main>

        {/* Footer */}
        <footer className="bg-dark text-white py-4 mt-5">
          <Container>
            <Row>
              <Col className="text-center">
                <p>Sindicato de Trabajadores SINTEREC, Claro Chile</p>
                <Nav className="justify-content-center">
                  <Nav.Link href="/noticias" className="text-white">Noticias</Nav.Link>
                  <Nav.Link href="/directiva-sindicato" className="text-white">Directiva Sindicato</Nav.Link>
                  <Nav.Link href="/contacto" className="text-white">Contacto</Nav.Link>
                </Nav>
              </Col>
            </Row>
            <Row className="text-center mt-3">
              <Col>
                <p>&copy; SINTEREC.cL Todos los derechos reservados.</p>
              </Col>
            </Row>
          </Container>
        </footer>
      </div>
    </Container>
  );
}

export default App;
